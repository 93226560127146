import React from 'react'
import { DateTime } from 'luxon'
import { Entity, SubmitButtonComponent } from '@parallelpublicworks/entitysync'
import { ImpactContext } from '../components/Layout'
import { CircularProgress, Modal, Typography, Button } from '../components/library'
import { RadioField, FrequencyField, InputField } from '../components/library/entitysync'
import goalTypes from '../utils/goalTypes'
import setBonesToBeAdded from '../utils/setBonesToBeAdded'
import getNameWithPossessiveApostrophe from '../utils/getNameWithPossessiveApostrophe'
import { navigate } from 'gatsby'

const SubmitButton = SubmitButtonComponent(props => {
  return <Button onClick={props.onClick} {...props} />
})

function GoalFormPage({ location }) {
  const { response, backTo } = location.state
  const [loading, setLoading] = React.useState(false)
  const { userData, profiles, currentWeek, weeks, today } = React.useContext(ImpactContext)
  const [isNewGoal, setIsNewGoal] = React.useState(null)
  const [source, setSource] = React.useState(null)
  const [nodeType, setNodeType] = React.useState(null)
  const [profileLabels, setProfileLabels] = React.useState(null)
  const [errorField, setErrorField] = React.useState(false)
  const [currentGoalType, setCurrentGoalType] = React.useState(
    response && response?.attributes?.field_goal_type ? goalTypes.find(type => type.key === response.attributes.field_goal_type) : null
  )
  const [userDataChangesCount, setUserDataChangesCount] = React.useState(0)
  const [goingAway, setGoingAway] = React.useState(false)
  const [boneID, setBoneID] = React.useState(null)

  React.useEffect(() => {
    setBoneID(DateTime.now().toISO())
  }, [])

  React.useEffect(() => {
    if (loading) {
      setUserDataChangesCount(userDataChangesCount + 1)
    }
  }, [userData, loading])

  React.useEffect(() => {
    if (userDataChangesCount >= 3 && !goingAway) {
      if (backTo.includes('getting-started')) {
        setBonesToBeAdded(1, boneID, 'Good job with setting your first goal! You’ve earned 1 bone. ', false, 'You’ve set 1 goal!')
      }
      setGoingAway(true)
      navigate(backTo)
    }
  }, [userDataChangesCount])

  React.useEffect(() => {
    if (typeof response !== 'undefined') {
      setNodeType(response.type)
      setSource(response)
      setIsNewGoal(false)
    } else {
      setNodeType('node--goal')
      setSource({})
      setIsNewGoal(true)
    }
  }, [])

  React.useEffect(() => {
    if (profiles !== null && profileLabels === null) {
      setProfileLabels({
        parent: getNameWithPossessiveApostrophe(profiles.parent),
        child: getNameWithPossessiveApostrophe(profiles.child)
      })
    }
  }, [profiles])

  const onSubmit = (e, unsavedChanges, entityData) => {
    setUserDataChangesCount(0)
    //       if(unsavedChanges && unsavedChanges.attributes && unsavedChanges.attributes.field_goal_type){
    //         const goal_type = goalTypes.find(type => type.key === unsavedChanges.attributes.field_goal_type)
    //         unsavedChanges.attributes.title = goal_type ? goal_type.name : 'My Goal'
    //         let week_date = currentWeek.interval.s.toISO()
    //         if(currentWeek.todo_done && typeof weeks[currentWeek.number + 1] !== 'undefined'){
    //           week_date = weeks[currentWeek.number + 1].interval.s.toISO()
    //         }
    //         unsavedChanges.attributes.field_week_date_iso = week_date
    //         unsavedChanges.attributes.field_goal_week = `week${currentWeek.number}`
    //       }
    //       setLoading(true)
    //   }
    let validpatternnumbers = /^([1-7])?$/
    let attr = { ...unsavedChanges.attributes }
    attr = entityData?.attributes?.changed ? { ...entityData.attributes } : (attr = attr)
    let checkedField = validpatternnumbers.test(parseFloat(attr.field_check_in_frequency))
    if (checkedField === false && attr.field_check_in_frequency) {
      setErrorField('Minimum 3')
      return e.preventDefault()
    } else {
      setErrorField(false)
      if (unsavedChanges && unsavedChanges.attributes && unsavedChanges.attributes.field_goal_type) {
        const goal_type = goalTypes.find(type => type.key === unsavedChanges.attributes.field_goal_type)
        unsavedChanges.attributes.title = goal_type ? goal_type.name : 'My Goal'
        let week_date = currentWeek.interval.s.toISO()
        if (currentWeek.todo_done && typeof weeks[currentWeek.number + 1] !== 'undefined') {
          week_date = weeks[currentWeek.number + 1].interval.s.toISO()
        }
        // unsavedChanges.attributes.field_week_date_iso = week_date
        unsavedChanges.attributes.field_goal_week = `week${currentWeek.number}`
      }
      setLoading(true)
    }
  }

  const goalTypeChanged = (e, new_value) => {
    setCurrentGoalType(goalTypes.find(type => type.key === new_value))
  }

  if (source === null || nodeType === null || isNewGoal === null || profiles === null || profileLabels === null) return <CircularProgress />

  if (
    currentWeek.goals_are_all_checked_in &&
    ((today < currentWeek.interval.s && today > weeks[0].interval.s) ||
      (typeof weeks[currentWeek.number + 1] !== 'undefined' && today > weeks[currentWeek.number + 1].interval.e))
  ) {
    navigate('/goals')
  }

  return (
    <Entity type={nodeType} source={source} componentId="goal-form-entity">
      <Modal
        open="true"
        title={`${isNewGoal ? 'Add Goal' : 'Edit Goal'}`}
        size="sm"
        handleClose={e => navigate(-1)}
        disabled={loading}
        footer={
          <SubmitButton disabled={loading} onSubmit={onSubmit} variant="contained" color="primary">
            Done
          </SubmitButton>
        }
      >
        {nodeType === 'node--asthma_check_in_goal' && (
          <InputField
            field="field_check_in_frequency"
            label="Daily check-in for this week:"
            type="number"
            error={errorField}
            // helperText="Set the minimum number of daily check-ins for this week"
            helperText={errorField}
            disabled={loading}
          />
        )}
        {nodeType !== 'node--asthma_check_in_goal' && (
          <>
            <RadioField
              field="field_goal_type"
              options={goalTypes.map(type => ({ value: type.key, label: type.description }))}
              onChange={goalTypeChanged}
              disabled={loading}
            />

            {currentGoalType !== null && (
              <>
                {currentGoalType.key === 'daily_medication_goal' && (
                  <>
                    <FrequencyField ampm="am" disabled={loading} />
                    <FrequencyField ampm="pm" disabled={loading} />
                  </>
                )}

                {currentGoalType.key === 'write_own' && (
                  <InputField field="field_goal_description" label={`Goal description:`} multiline disabled={loading} />
                )}

                <Typography variant="caption">{currentGoalType.barrier_question}</Typography>
                <InputField field="field_parent_stopping" label={`${profileLabels.parent} answer:`} multiline disabled={loading} />
                <InputField field="field_child_stopping" label={`${profileLabels.child} answer:`} multiline disabled={loading} />

                <Typography variant="caption">{currentGoalType.strategy_question}</Typography>
                <InputField
                  field="field_strategies"
                  label="Strategies:"
                  multiline
                  helperText={currentGoalType.strategy_helper_text}
                  disabled={loading}
                />
                {currentGoalType.key === 'daily_medication_goal' && (
                  <Typography variant="caption">
                    If you would like IMPACT to send you text reminders, please set your{' '}
                    <a href="/account" target="_blank">
                      medication notification preferences
                    </a>
                    .
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </Entity>
  )
}

export default GoalFormPage
